import RefComponentComponent from '@wix/thunderbolt-elements/src/components/RefComponent/viewer/RefComponent';


const RefComponent = {
  component: RefComponentComponent
};


export const components = {
  ['RefComponent']: RefComponent
};

